import * as React from "react"
import "./rent.scss"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import RealEstate from "./components/listings"



const RentListings = () => (
  <Layout>
    <Seo title="Royal Palm Destinations | Royal Palm Destinations Real Estate " />
    <RealEstate/>
  </Layout>
)

export default RentListings;                         
         